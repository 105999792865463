import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./webtrader.scss";

const app_config = {
  clientName: "Newera Capital Markets",
  sidebarTitle: "Newera Capital Markets Client Portal",
  webTraderServer: "NeweraCapitalMarkets-Server",
  webTraderVersion: 5,
  webTraderUtmSource: "#",
  contactUs: "#",
  linkDesktop:
    "#",
  linkAndroid: "#",
  linkIos: "#",
};

const link = "#";

const WebTrader = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [height, setHeight] = React.useState(700);
  useEffect(() => {
    const { height } = getWindowDimensions();
    setHeight(height - height * 0.1);
  }, []);

  return (
    <>
      <div id="webterminal" className={"web-terminal"} style={{
        height,
        zIndex: 3,
        position: "relative",
      }}>
        <iframe style={{
          height: "100%",
          width: "100%",
        }} src={link} allowfullscreen="allowfullscreen"></iframe>
      </div>
      <Helmet>
        {/* <script src='https://trade.mql5.com/trade/widget.js'></script> */}

        <script type="text/javascript">
          {/* {`
            setTimeout(() => {
              new MetaTraderWebTerminal( "webterminal", {
                version: ${app_config.webTraderVersion},
                servers: ["${app_config.webTraderServer}"],
                server: "${app_config.webTraderServer}",
                demoAllServers: true,
                utmSource: "${app_config.webTraderUtmSource}",
                startMode: "create_demo",
                language: "en",
                colorScheme: "black_on_white"
            } );
          }, 1000);
          `} */}
        </script>
      </Helmet>
    </>
  );
};

export default WebTrader;
