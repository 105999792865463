import {
  companyName,
  privacyPolicyLink,
  clientAgreementLink,
  companyWebsite,
  executionPolicy,
  conflictPolicy,
  disclosurePolicy,
  LPOAAgreementLink,
} from "./content";


// export const CLIENT_AGREEMENT = `You have read, understood, and agreed to ${companyName}'s client agreement which includes order execution policy, conflict of interest policy, <a href=${privacyPolicyLink} target='_blank'>privacy policy</a>, 3rd party disclosure policy and any other terms in the <a href=${clientAgreementLink} target='_blank'>client agreement</a>.`;
export const CLIENT_AGREEMENT = `This is to confirm that you have read, understood, and agreed to Newera Capital Markets's  <a href=${clientAgreementLink} target='_blank'>Client Agreement</a> which includes order <a href=${executionPolicy} target='_blank'>Execution Policy</a>, <a href=${conflictPolicy} target='_blank'>Conflict of Interest Policy</a>, <a href=${privacyPolicyLink} target='_blank'>Privacy Policy</a>, <a href=${disclosurePolicy} target='_blank'>3rd Party disclosure Policy</a> and all other terms as mentioned in the Client Agreement.`;
export const REGISTER = `By clicking here I give my consent for ${companyName} to contact me for marketing purposes. You can opt out at any time. For further details please see our Marketing and Communication Policy Statement.`;

export const IB_AGREEMENT = `You have read, understood, acknowledged, and agreed to all ${companyName}'s policies, terms & conditions and client agreements which are available on the company's <a href=${companyWebsite} target='_blank'>website</a>`;

export const COUNTRY_REGULATIONS = `You confirm that you do not breach any regulation of your country of residence in trading with ${companyName}.`;

export const E_SIGNATURE = "Your electronic signature is considered a legal and official signature.";

export const LPOA_Agreement = `<a href=${LPOAAgreementLink} target='_blank'> By ticking the boxes, you agreed with the terms and condition stated in the document here</a>`;
